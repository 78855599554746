<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item label="模板内容">
              <a-input placeholder="请输入模板内容" v-model="queryParam.templateContent"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="模板主题">
              <a-input placeholder="请输入模板主题" v-model="queryParam.templateName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-button type="primary" icon="download" @click="handleExportXls('消息模板')">导出</a-button>
      <a-upload
        name="file"
        :showUploadList="false"
        :multiple="false"
        :headers="tokenHeader"
        :action="importExcelUrl"
        @change="handleImportExcel"
      >
        <a-button type="primary" icon="import">导入</a-button>
      </a-upload>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"> <a-icon type="delete" />删除 </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">
          批量操作
          <a-icon type="down" />
        </a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
        <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
        >项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        @expand="expand"
        :pagination="ipagination"
        :expandedRowKeys="expandedRowKeys"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 446px)' }"
      >
        <!-- 字符串超长截取省略号显示-->
        <span slot="templateName" slot-scope="text, record">
          <div v-html="record.templateName"></div>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>

          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleTest(record)">发送测试</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
        <a-table
          slot="expandedRowRender"
          style="background-color: #fff;"
          class="content-table"
          slot-scope="text"
          :columns="innerColumns"
          :data-source="innerData"
          :pagination="false"
        >
          <template slot="templateContent" slot-scope="text, record">
            <div v-html="record.templateContent" style="white-space: pre-line;text-align:left"></div>
          </template>
        </a-table>
      </a-table>
    </div>
    <!-- table区域-end -->

    <!-- 表单区域 -->
    <sysMessageTemplate-modal
      :sceneOptions="mapToOptions(this.sceneMap)"
      :sendTypeOptions="mapToOptions(this.sendTypeMap)"
      :priorityMap="this.priorityMap"
      ref="modalForm"
      @close="close"
      @ok="modalFormOk"
    ></sysMessageTemplate-modal>

    <sysMessageTest-modal ref="testModal"></sysMessageTest-modal>
  </a-card>
</template>

<script lang="jsx">
import { mapToOptions } from '@/utils/util'
import SysMessageTemplateModal from './modules/SysMessageTemplateModal'
import SysMessageTestModal from './modules/SysMessageTestModal'
import { getAction } from '@/api/manage'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import JEllipsis from '@/components/jeecg/JEllipsis'

export default {
  name: 'SysMessageTemplateList',
  mixins: [JeecgListMixin],
  components: {
    JEllipsis,
    SysMessageTemplateModal,
    SysMessageTestModal
  },
  data() {
    return {
      sceneMap: new Map()
        .set("WELCOME", "新账号注册")
        .set("TASK_READY", "新任务")
        .set("TASK_START", "可开始")
        .set("TASK_BEEN_KPI", "已审核")
        .set("TASK_TO_KPI", "未审核")
        .set("GRADE", "评级")
        .set("TASK_WARNING", "预警")
        .set("PRODUCTION_CHAPTER_NOTE", "备注")
        .set("QUESTION_ANSWER_REPLY", "帮助与反馈")
        .set("TASK_SCHEDULE_CHANGE_APPLY", "可安排数量")
        .set("TASK_REARRANGE", "不能完成")
        .set("TASK_DELAY", "拖稿")
        .set("TASK_REJECT", "驳回")
        .set("TASK_DELAY_FINISH", "拖稿完成")
        .set("SETTLE_BOSS_CONFIRMED", "结算")
        .set("SETTLE_BOSS_PAYED", "已支付"),
      sendTypeMap: new Map()
        // .set("UNKNOWN", "未知")
        // .set("SMS", "短信")
        .set("SYSTEM", "系统消息")
        .set("EMAIL", "邮件")
        .set("WECHAT", "公众号"),
      priorityMap: new Map()
        // .set("UNKNOWN", "未知")
        .set("LOW", "低")
        .set("MIDDLE", "中")
        .set("HIGH", "高")
        /* .set("SYSTEM", "跟随系统") */,
      description: '消息模板管理页面',
      // 表头
      columns: [
        {
          title: '序号',
          width: 40,
          align: "center",
          dataIndex: 'sort'
        },
        {
          title: '应用场景',
          align: 'center',
          dataIndex: 'scene',
          width: 140,
          customRender: i => this.sceneMap.get(i)
        },
        {
          title: '主题',
          align: 'center',
          dataIndex: 'templateName',
          scopedSlots: { customRender: 'templateName' },
          width: 520,
        },
        {
          title: '发送方式',
          align: 'center',
          dataIndex: 'sendTypes',
          width: 180,
          customRender: list => list?.map(i => (<a-tag>{this.sendTypeMap.get(i)}</a-tag>))
        },
        {
          title: '优先级',
          align: 'center',
          dataIndex: 'priority',
          width: 120,
          customRender: i => this.priorityMap.get(i)
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
          width: 150
        }
      ],
      expandedRowKeys: [],
      innerColumns: [
        {
          title: '模板内容',
          dataIndex: 'templateContent',
          scopedSlots: { customRender: 'templateContent' }
        }
      ],
      innerData: [],
      fontColor:'',
      url: {
        list: '/sys/message/sysMessageTemplate/list',
        delete: '/sys/message/sysMessageTemplate/delete',
        deleteBatch: '/sys/message/sysMessageTemplate/deleteBatch',
        exportXlsUrl: 'sys/message/sysMessageTemplate/exportXls',
        importExcelUrl: 'sys/message/sysMessageTemplate/importExcel'
      },
      typeList:[
        /* {label:'昵称',value:'${item.realname}'}, */
        {label:'邮箱',value:'${item.email}'},
        {label:'密码',value:'${item.password}'},
        {label:'作品名称',value:'${item.productName}'},
        {label:'章节序号',value:'${item.chapterOrder}'},
        {label:'章节名称',value:'${item.chapterName}'},
        {label:'阶段名称',value:'${item.taskName}'},
        {label:'截止日期',value:'${item.limitDate}'},
        {label:'截止时间',value:'${item.deadline}'},
        {label:'人员',value:'${item.member}'},
        {label:'原因',value:'${item.delayReason}'},
        {label:'驳回阶段名称',value:'${item.rejectStage}'},
        {label:'日期（年-月）',value:'${item.ratingDate}'},
        {label:'部门',value:'${item.department}'},
        {label:'职位',value:'${item.position}'},
        {label:'昵称',value:'${item.nickName}'},
        {label:'startIcon',value:'<#list list as item>'},
        {label:'endIcon',value:'</#list>'},
      ]
    }
  },
  mounted() {
    this.fontColor = this.$store.state.app.color
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    mapToOptions,
    close(){
      this.loadData()
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
        params.size = params.pageSize
        params.column = 'sort'
        params.order='asc'
        getAction(this.url.list, params).then(res => {
          console.log('JeecgListMixin loadData getAction over', { res })
          if (res.success) {
            //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
            res.data.records.forEach(item=>{
              this.typeList.forEach(tag=>{
                if(tag.label!='startIcon'&&tag.label!='endIcon'){
                  item.templateName = item.templateName.replaceAll(tag.value,`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`)
                  item.templateContent = item.templateContent.replaceAll(tag.value,`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`)
                  item.templateName = item.templateName.replaceAll(tag.value,`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`)
                  item.templateContent = item.templateContent.replaceAll(tag.value,`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`)
                }else if(tag.label=='startIcon'){
                  item.templateContent = item.templateContent.replaceAll(tag.value,`<span style="margin-left:5px;margin-right:15px;position: relative;top: -3px;"><span style='display: block; width: 0;height: 0;border-width: 8px 0 8px 12px;border-style: solid;color: rgba(0, 0, 0, 0);border-color: transparent transparent transparent #fff; position: absolute;top:5px;left:0;border-left-color:${this.fontColor}'>startIcon</span></span>`)
                  item.templateContent = item.templateContent.replaceAll(tag.value,`<span style="margin-left:5px;margin-right:15px;position: relative;top: -3px;"><span style='display: block; width: 0;height: 0;border-width: 8px 0 8px 12px;border-style: solid;color: rgba(0, 0, 0, 0);border-color: transparent transparent transparent #fff; position: absolute;top:5px;left:0;border-left-color:${this.fontColor}'>startIcon</span></span>`)
                }else if(tag.label=='endIcon'){
                  item.templateContent = item.templateContent.replaceAll(tag.value,`<span style="overflow: hidden;width: 15px;height: 15px;display: inline-block;border-radius: 3px;position: relative;color: rgba(0, 0, 0, 0);left: -2px;top: 2px;background-color:${this.fontColor}"><span>endIcon</span></span>`)
                  item.templateContent = item.templateContent.replaceAll(tag.value,`<span style="overflow: hidden;width: 15px;height: 15px;display: inline-block;border-radius: 3px;position: relative;color: rgba(0, 0, 0, 0);left: -2px;top: 2px;background-color:${this.fontColor}"><span>endIcon</span></span>`)
                }
              })
            })
            this.dataSource = res.data.records || res.data
            if (res.data.total) {
              this.ipagination.total = res.data.total - 0
            } else {
              this.ipagination.total = 0
            }
            //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

            // 字体预览需要往head里插入css样式
            if (this.isInserstCss) {
              batchStyleInsertHeader(this.dataSource)
            }
            this.$emit('loadData', this.dataSource)
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
        this.loading = false
      })
    },
    handleEdit: function(record) {
      let recordCopy = this.data = JSON.parse(JSON.stringify(record));
      this.typeList.forEach(tag=>{
        if(tag.label!='startIcon'&&tag.label!='endIcon'){
          recordCopy.templateName = recordCopy.templateName.replaceAll(`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`,tag.value)
          recordCopy.templateContent = recordCopy.templateContent.replaceAll(`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`,tag.value)
          recordCopy.templateName = recordCopy.templateName.replaceAll(`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`,tag.value)
          recordCopy.templateContent = recordCopy.templateContent.replaceAll(`<span style='margin-left:5px' class='ant-tag'>${tag.label}</span>`,tag.value)
        }else if(tag.label=='startIcon'){
          recordCopy.templateContent = recordCopy.templateContent.replaceAll(`<span style="margin-left:5px;margin-right:15px;position: relative;top: -3px;"><span style='display: block; width: 0;height: 0;border-width: 8px 0 8px 12px;border-style: solid;color: rgba(0, 0, 0, 0);border-color: transparent transparent transparent #fff; position: absolute;top:5px;left:0;border-left-color:${this.fontColor}'>startIcon</span></span>`,tag.value)
          recordCopy.templateContent = recordCopy.templateContent.replaceAll(`<span style="margin-left:5px;margin-right:15px;position: relative;top: -3px;"><span style='display: block; width: 0;height: 0;border-width: 8px 0 8px 12px;border-style: solid;color: rgba(0, 0, 0, 0);border-color: transparent transparent transparent #fff; position: absolute;top:5px;left:0;border-left-color:${this.fontColor}'>startIcon</span></span>`,tag.value)
        }else if(tag.label=='endIcon'){
          recordCopy.templateContent = recordCopy.templateContent.replaceAll(`<span style="overflow: hidden;width: 15px;height: 15px;display: inline-block;border-radius: 3px;position: relative;color: rgba(0, 0, 0, 0);left: -2px;top: 2px;background-color:${this.fontColor}"><span>endIcon</span></span>`,tag.value)
          recordCopy.templateContent = recordCopy.templateContent.replaceAll(`<span style="overflow: hidden;width: 15px;height: 15px;display: inline-block;border-radius: 3px;position: relative;color: rgba(0, 0, 0, 0);left: -2px;top: 2px;background-color:${this.fontColor}"><span>endIcon</span></span>`,tag.value)
        }
       })
      this.$refs.modalForm.edit(recordCopy)
      this.$refs.modalForm.title = '编辑'
      this.$refs.modalForm.disableSubmit = false
    },
    expand(e, row) {
      if (e) {
        this.innerData = [row]
        this.expandedRowKeys = [row.id]
      } else {
        this.expandedRowKeys = this.expandedRowKeys.filter(item => {
          return item != row.id
        })
      }
    },
    handleTest(record) {
      this.$refs.testModal.open(record)
      this.$refs.testModal.title = '发送测试'
    }
  }
}
</script>
<style lang="less" scoped>
.triangle_border_right span {
  display: block;
  width: 0;
  height: 0;
  border-width: 8px 0 8px 12px;
  border-style: solid;
  color: rgba(0, 0, 0, 0);
  border-color: transparent transparent transparent #fff;
  position: absolute;
}
/deep/ .ant-table-expanded-row {
  background-color: #fff !important;

  td,
  th {
    background-color: #fff !important;
  }

  th {
    border: none !important;
  }
}

/** Button按钮间距 */
/deep/ .ant-table-body-inner {
  max-height: calc(100vh - 436px) !important;
}

.ant-btn {
  margin-left: 3px;
}

.ant-card-body .table-operator {
  margin-bottom: 18px;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.anty-row-operator button {
  margin: 0 5px;
}

.ant-btn-danger {
  background-color: #ffffff;
}

.ant-modal-cust-warp {
  height: 100%;
}

.ant-modal-cust-warp .ant-modal-body {
  height: calc(100% - 110px) !important;
  overflow-y: auto;
}

.ant-modal-cust-warp .ant-modal-content {
  height: 90% !important;
  overflow-y: hidden;
}
</style>
